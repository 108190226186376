export const defaultTitle = "Cicilan Ringan Tanpa Beban";

export const defaultDescription =
  "Miliki HP, Laptop, TV dan elektronik lainnya ✅ dengan cicilan ringan tanpa beban ✅ dari pembiayaan yang mudah, praktis dan cepat Home Credit Indonesia ✅ Pengajuan bisa langsung maupun online.";
export const defaultURL = process.env.baseUrl;

export const kreditMultigunaMeta = {
  title: "Kredit Multiguna Termudah dan Paling Aman",
  description:
    "Biaya liburan, biaya menikah, biaya renovasi rumah, biaya sekolah, dan kebutuhan lainnya - Home Credit",
  path: "/kredit-multiguna",
};
export const cicilanOnlineMeta = {
  title: "Kredit HP, Elektronik, TV dan Barang Apapun Online Bisa Bebas Bunga",
  description:
    "Kredit Handphone, Elektronik, TV, Kamera, Gadget,  AC dan semua barang impian kamu ✅ dengan mudah, praktis, dan bisa bebas bunga ✅ melalui online dari Home Credit.",
};
export const belanjaDitokoMeta = {
  title: "Belanja di toko",
  description:
    "Temukan segala informasi mengenai produk-produk yang dibiayai oleh Home Credit Indonesia. Anda bisa mendapatkan fasilitas kredit Handphone, Kamera, TV, Gadget, AC dan lainnya tanpa kartu kredit disini.",
};
export const promoDanPenawaranMeta = {
  title: "Promo dan Penawaran",
  path: "/promo-dan-penawaran",
};

export const proteksiMeta = {
  title: "Proteksi Yang Disediakan",
  description:
    "Proteksi tambahan untuk cicilan Anda di Home Credit. Mulai dari proteksi perangkat sampai dengan proteksi cicilan Anda.",
};
export const asuransiIsiRumahMeta = {
  title: "Asuransi Isi Rumah - SANTAI",
  description:
    "SANTAI merupakan asuransi isi rumah terbaik yang wajib kamu miliki.",
  path: "/asuransi-isi-rumah",
};

export const asuransiIsiRumahMalacca = {
  title: "Asuransi Isi Rumah - Malacca",
  description:
    "SANTAI merupakan asuransi isi rumah terbaik yang wajib kamu miliki.",
  path: "/asuransi-isi-rumah/Malacca",
};

export const asuransiIsiRumahMSIG = {
  title: "Asuransi Isi Rumah - MSIG",
  description:
    "SANTAI merupakan asuransi isi rumah terbaik yang wajib kamu miliki.",
  path: "/asuransi-isi-rumah/MSIG",
};

export const asuransiIsiRumahZURICH = {
  title: "Asuransi Isi Rumah - ZURICH",
  description:
    "SANTAI merupakan asuransi isi rumah terbaik yang wajib kamu miliki.",
  path: "/asuransi-isi-rumah/ZURICH",
};

export const asuransiCicilanKreditMeta = {
  title: "Asuransi Cicilan (Angsuran) Kredit - AMAN",
  description:
    "Perusahaan asuransi yang bekerjasama dengan Home Credit menjamin pembayaran angsuran atau cicilan bila nasabah mengalami kejadian berikut.",
  path: "/asuransi-cicilan-kredit",
};

export const asuransiMotorMeta = {
  title: "Asuransi Kendaraan Bermotor - MOTOCOVER",
  description:
    "Perusahaan asuransi yang bekerjasama dengan Home Credit menjamin kendaraan bermotor pelanggan apabila mengalami kejadian berikut ini.",
  path: "/asuransi-motor",
};

export const caraPembayaranMeta = {
  title: "Mitra & Cara Pembayaran Angsuran",
  description:
    "Temukan cara pembayaran cicilan bulanan Anda melalui bank dan mitra Home Credit Indonesia di sini.",
  path: "/cara-pembayaran",
};

export const proSeriesMeta = {
  title: "Asuransi Barang Elektronik Bergerak - ProSERIES",
  description:
    "Gunakan proSERIES sebagai asuransi perangkat Hp, gadget, tablet, atau laptop akibat kerusakan fisik atau terkena cairan.",
  path: "/asuransi-barang-elektronik",
};
export const asuransiGadgetMeta = {
  title: "Asuransi Handphone, Gadget dan Laptop - EASYCOVER",
  description:
    "Gunakan EASYCOVER sebagai asuransi perangkat Hp, gadget, tablet, atau laptop akibat kerusakan yang tidak disengaja.",
  path: "/asuransi-gadget",
};

export const tentangPerusahaanMeta = {
  title: "Tentang Perusahaan",
  description: "Tentang Home Credit Indonesia sebagai Perusahaan Pembiayaan.",
  path: "/tentang-perusahaan",
};

export const siaranPersMeta = {
  title: "Siaran Pers",
  description: "Berita terbaru dari perkembangan bisnis Home Credit Indonesia.",
};

export const penghargaanKamiMeta = {
  title: "Penghargaan Kami",
  description:
    "Setiap harinya, kami memberikan yang terbaik untuk menjadikan semua yang bermakna dalam hidup pelanggan di dalam jangkauan. Kami lakukan ini dengan terus berinovasi dan mengembangkan layanan kami.",
};

export const syaratDanKetentuanMeta = {
  title: "Syarat dan Ketentuan",
  description:
    "Halaman ini menampilkan Syarat dan Ketentuan sehubungan dengan layanan Home Credit Indonesia yang terdaftar di website kami kepada anda.",
};

export const syaratDanKetentuaMultiwithdrawalnMeta = {
  title: "Syarat dan Ketentuan Multiwithdrawal",
  description: "Syarat dan Ketentuan Multiwithdrawal",
};

export const syaratDanKetentuaAPlikasiMeta = {
  title: "Syarat dan Ketentuan Aplikasi Home Credit Indonesia",
  description: "Syarat dan Ketentuan Aplikasi Home Credit Indonesia",
};

export const kebijakanPrivasiMeta = {
  title: "Kebijakan Privasi",
  description:
    "Berikut adalah kebijakan privasi yang berlaku di PT Home Credit Indonesia.",
};

export const blogMeta = {
  title: "Blog",
  description:
    "Temukan artikel - artikel menarik mengenai keuangan dan gaya hidup di sini. Selalu update setiap minggu.",
  path: "/blog",
};
export const tandaTanganMeta = {
  title: "Tanda Tangan Elektronik",
  description:
    "Lewat portal tanda tangan elektronik, Anda dapat menandatangani Perjanjian Pembiayaan Anda secara lebih mudah dan praktis. Dalam waktu 5 menit, Anda dapat memberikan tanda tangan elektronik ✅ kapan pun, di mana pun.",
  path: "/tanda-tangan-elektronik",
};
export const panduanKonsumenMeta = {
  title: "Panduan Konsumen",
  description:
    "Panduan informasi denda, kredit kedua, dan penyampaian keluhan untuk konsumen Home Credit Indonesia.",
};

export const biayaDendaDanBungaMeta = {
  title: "Biaya, Denda, dan Bunga",
  description:
    "Panduan informasi tentang biaya, denda keterlambatan pembayaran, dan besaran bunga pembiayaan di Home Credit Indonesia.",
};

export const formulirSanggahanMeta = {
  title: "Formulir Sanggahan",
  description:
    "Temukan solusi untuk masalah Anda di halaman keluhan kami. Kami menyediakan platform mudah untuk menyampaikan keluhan dan mendapatkan bantuan cepat. Lihat panduan kami, kirimkan keluhan, dan dapatkan respon profesional dari tim kami untuk menyelesaikan masalah Anda dengan efektif.",
  path: "/panduan-konsumen/formulir-sanggahan",
};

export const pertanyaanUmumMeta = {
  title: "Pertanyaan Umum",
  description:
    "Jawaban dari pertanyaan-pertanyaan yang sering diajukan konsumen Home Credit Indonesia.",
};

export const mitraMeta = {
  title: "Mitra",
  description:
    "Home Credit bekerja sama dengan peritel dan toko ternama untuk menghadirkan pembiayaan yang mudah, praktis, dan cepat kepada Anda. Menjadi Mitra Home Credit Indonesia dengan segala keuntungan dan kemudahan.",
  path: "/mitra",
};

export const lokasiKamiMeta = {
  title: "Temukan Lokasi Kami",
  description:
    "Izinkan website kami membaca lokasi Anda, agar kami dapat memberikan daftar toko mitra kami yang terdekat dalam radius 5km dari lokasi Anda.",
};

export const myHomecreditMeta = {
  title: "My Home Credit App",
  description:
    "Mengatur pembiayaan jadi lebih mudah dengan aplikasi My Home Credit.",
};

export const hubungKamiMeta = {
  title: "Hubungi Kami",
  description: "Hubungi Home Credit Indonesia melalui form yang tersedia.",
};
export const keluhanMeta = {
  title: "Keluhan",
  description:
    "Home Credit mulai berdiri di Indonesia pada tahun 2013 di area Jakarta. Hingga awal tahun 2017, bisnis kami berkembang tidak hanya di Jabodetabek, tetapi juga di Bandung, Makassar, Surabaya, Yogyakarta, Semarang, Malang, Denpasar, Pekanbaru, Medan, Batam, Palembang, Banjarmasin, Pontianak, Manado, dan Balikpapan. Kami berencana untuk mengembangkan layanan ke seluruh kota-kota di Indonesia hingga tahun 2018.",
  path: "/panduan-konsumen/keluhan",
};
export const myHomeCreditPaymentMeta = {
  title: "Pembayaran Melalui Aplikasi My Home Credit",
  description:
    "Panduan cara pembayaran cicilan Home Credit Indonesia melalui Aplikasi My Home Credit.",
  path: "/cara-pembayaran/my-home-credit-app",
};

export const transferBankPaymentMeta = {
  title: "Pembayaran Melalui Transfer Bank",
  description:
    "Panduan cara pembayaran cicilan Home Credit Indonesia melalui transfer Bank.",
  path: "/cara-pembayaran/transfer-bank",
};

export const bayarOtomatisMeta = {
  title: "Bayar Otomatis",
  description:
    "Metode pembayaran Home Credit Indonesia menggunakan fitur Bayar Otomatis yang bekerjasama dengan AyoConnect (AyoPop) disediakan bagi pelanggan untuk mempermudah proses pembayaran cicilan pembiayaan secara otomatis (Autodebit) setiap bulannya.",
  path: "/cara-pembayaran/bayar-otomatis",
};

export const isiSaldoHomeCreditPayMeta = {
  title: "Isi Saldo Home Credit Pay - Home Credit",
  description:
    "Panduan cara isi saldo Home Credit Pay melalui mitra terpercaya Home Credit Indonesia.",
  path: "/cara-pembayaran/isi-saldo-home-credit-pay",
};

export const geraiRetailMeta = {
  title: "Pembayaran Melalui Gerai Retail",
  description:
    "Panduan cara pembayaran cicilan Home Credit Indonesia melalui gerai retail.",
  path: "/cara-pembayaran/gerai-retail",
};

export const eCommerceMeta = {
  title: "Pembayaran Melalui E-Commerce",
  description:
    "Panduan cara pembayaran cicilan Home Credit Indonesia melalui website E-Commerce.",
  path: "/cara-pembayaran/e-commerce",
};

export const eMoneyMeta = {
  title: "Pembayaran Melalui Electronic Money",
  description:
    "Panduan cara pembayaran cicilan Home Credit Indonesia melalui website Electronic Money.",
  path: "/cara-pembayaran/electronic-money",
};
export const flexiFastMeta = {
  title: "FlexiFast : Pinjaman Tunai Tanpa Jaminan Flexifast - Home Credit",
  description:
    "Dapatkan pinjaman dana tunai online FlexiFast bagi pelanggan setia Home Credit Indonesia. Tanpa kartu kredit, syarat super mudah, aman, cepat cair dan terpercaya.",
  path: "/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan",
};
export const twoWheelersMeta = {
  title: "√ Kredit Motor, Cicilan Murah dan Proses Cepat",
  description:
    "Kredit motor dengan cicilan murah ✅ dan proses super cepat ✅ ada di Home Credit. Dapatkan motor impian kamu bersama kami ya.",
};

export const rewardsMeta = {
  title: "Katalog Rewards Terbaru",
  description:
    "Jadi pelanggan setia Home Credit, raih beragam Reward Diskon menariknya.",
  path: "/rewards",
};

export const kartuKreditMeta = {
  title: "Apply Kartu Kredit",
  description:
    "Kartu kredit dengan promo dan limit terbaik, cara membuatnya lakukan pengajuan di Home Credit ya, bunga ringan dan pembayaran tagihan sangat mudah.",
  path: "/kartukredit",
};
export const gueSiapMeta = {
  title: "Gue Siap Ambil #LangkahBERANI",
  description:
    "Saatnya lebih dekat dengan mimpi!Ikuti kompetisinya, bagikan momen terbaikmu dengan hashtag #GueSiap Ambil #LangkahBERANI, dan menangkan hadiahnya!",
};
export const protexiMeta = {
  title: "PROTEXI - asuransi tagihan kartu kredit",
  description:
    "PROTEXI adalah produk asuransi perlindungan atas pembayaran cicilan/ tagihan home credit card dari risiko tak terduga.",
  path: "/asuransi-tagihan-kartu-kredit",
};
export const homeCreditGoMeta = {
  title: "#TebelinDompet s.d. Rp1 juta sama Home Credit GO!",
  description:
    "Saatnya jadi GO-Ers biar bisa #TebelinDompet hingga Rp1 juta setiap bulannya. Let's GO!",
  path: "/go-ers",
};
export const promoCCMeta = {
  title: "Kini Belanja #JadiGampang",
  description:
    "Dapatkan penawaran menarik, diskon, hingga cashback dengan berbelanja menggunakan Home Credit Card di berbagai toko/merchant pilihan kami.",
  path: "/kartukredit/promo",
};
export const HCPCepatMeta = {
  title: "#LebihMudah dengan Home Credit Pay",
  description:
    "Transaksi untuk kebutuhan sehari-hari sekarang lebih mudah dengan Home Credit Pay di aplikasi My Home Credit.",
  path: "/uang-elektronik/pembelian-dan-pembayaran",
};
export const HCPMeta = {
  title: "#LebihMudah dengan Home Credit Pay",
  description:
    "Transaksi untuk kebutuhan sehari-hari sekarang lebih mudah dengan Home Credit Pay di aplikasi My Home Credit.",
  path: "/uang-elektronik",
};
export const MyLifeMeta = {
  title: "Asuransi Perlindungan Jiwa - MyLifeCover",
  description:
    "MyLifeCOVER adalah perlindungan jiwa yang dapat kamu manfaatkan demi jalani hidup maksimal tanpa perlu khawatir akan resiko tak terduga yang terjadi dalam 1 tahun penuh.",
  path: "/MyLifeCOVER",
};
export const SitemapMeta = {
  title: "Home Credit Indonesia - Sitemap",
  description: "Sitemap",
  path: "/sitemap",
};
export const CriticaLifeMeta = {
  title: "Asuransi Penyakit Kritis - CriticaLifeCOVER",
  description:
    "CriticaLifeCOVER adalah produk proteksi yang memberikan perlindungan atas risiko Meninggal Dunia atau didiagnosa menderita Penyakit Kritis",
  path: "/CriticaLifeCOVER",
};

export const RideCOVERMeta = {
  title: "Asuransi Motor - RideCOVER by Home Credit",
  description:
    "RideCOVER by Home Credit adalah perlindungan kendaraan bermotor roda dua yang melindungi kendaraan, pengendara, serta pihak ketiga yang dirugikan selama satu tahun penuh.",
  path: "/RideCOVER",
};

export const PayLaterMeta = {
  title:
    "Belanja Sesuka Hati Bisa #BayarNanti dengan Paylater dari Home Credit",
  description:
    "Gunakan Home Credit BayarNanti untuk belanja kebutuhan harianmu mulai dari ngopi, bayar makan, belanja di warung, bahkan belanja bulanan dengan satu langkah: scan QRIS.",
  path: "bayarnanti",
};

export const ModalUsahaMeta = {
  title: "Dapatkan Modal Usaha Home Credit hingga Rp25 juta",
  description:
    "Manfaatkan pembiayaan ini sebagai modal memulai usaha ataupun tambahan modal usaha tanpa perlu jaminan.",
  path: "/modal-usaha",
};

export const DashboardMeta = {
  title: "Dashboard",
  description: "Dashboard",
};

export const SobatBelanjaMeta = {
  title: "#BaikDiManaAja bareng #SobatBelanja",
  description: "Karena apapun keadaannya, kamu selalu bisa #BaikDiManaAja 😊",
  path: "/sobatbelanja",
};

export const PosOnboardingMeta = {
  title: "Mitra - Onboarding",
  description: "Mitra - Onboarding",
  path: "/mitra/onboarding",
};

export const PosDataDiriMeta = {
  title: "Mitra - Data Diri",
  description: "Mitra - Data Diri",
  path: "/mitra/data-diri",
};

export const PosInformasiToko = {
  title: "Mitra - Informasi Toko dan Produk",
  description: "Mitra - Informasi Toko dan Produk",
  path: "/mitra/informasi-toko",
};

export const PosKontakPemilik = {
  title: "Mitra - Kontak Pemilik",
  description: "Mitra - Kontak Pemilik",
  path: "/mitra/kontak-pemilik",
};

export const PosWaktuOperasional = {
  title: "Mitra - Waktu Operasional",
  description: "Mitra - Waktu Operasional",
  path: "/mitra/waktu-operasional",
};

export const PosInformasiBankMeta = {
  title: "Mitra - Informasi Bank",
  description: "Mitra - Informasi Bank",
  path: "/mitra/informasi-bank",
};

export const PosDokumenBadanUsahaIndividu = {
  title: "Mitra - Dokumen Badan Usaha Individu",
  description: "Mitra - Dokumen Badan Usaha Individu",
  path: "/mitra/dokumen-badan-usaha/individu",
};

export const PosDokumenBadanUsahaPtCv = {
  title: "Mitra - Dokumen Badan Usaha PT atau CV",
  description: "Mitra - Dokumen Badan Usaha PT atau CV",
  path: "/mitra/dokumen-badan-usaha/pt-cv",
};

export const PosRingkasanDataMeta = {
  title: "Mitra - Ringkasan Data",
  description: "Mitra - Ringkasan Data",
  path: "/mitra/ringkasan-data",
};

export const UploadKTP = {
  title: "Basher - Document",
  description: "Basher - Document",
  path: "/basher/upload-ktp",
};

export const ThankYouPage = {
  title: "Basher - Thank You",
  description: "Basher - Thank You",
  path: "/basher/thank-you",
};

export const ExpiredPage = {
  title: "Basher - Expired",
  description: "Basher - Expired",
  path: "/basher/expired",
};

export const CancelledPage = {
  title: "Basher - Cancelled",
  description: "Basher - Cancelled",
  path: "/basher/cancelled",
};

export const SAOnboardingMeta = {
  title: "SA Hiring - Onboarding",
  description: "SA Hiring - Onboarding",
  path: "/sa-hiring/onboarding",
};

export const SADataDiriMeta = {
  title: "SA Hiring - Data Diri",
  description: "SA Hiring - Data Diri",
  path: "/sa-hiring/data-diri",
};

export const SAThankYouMeta = {
  title: "SA Hiring - Thank you",
  description: "SA Hiring - Thank you",
  path: "/sa-hiring/thank-you",
};

export const SAUploadKTP = {
  title: "SA Hiring - Upload KTP",
  description: "SA Hiring - Upload KTP",
  path: "/sa-hiring/upload-ktp",
};

export const ConfirmationSAOnboardingMeta = {
  title: "Confirmation SA Hiring - Onboarding",
  description: "Confirmation SA Hiring - Onboarding",
  path: "/sa-hiring/caf2/onboarding",
};

export const SACaf2OnboardingMeta = {
  title: "SA Hiring - CAF 2 - Onboarding",
  description: "SA Hiring - CAF 2 - Onboarding",
  path: "/sa-hiring/caf2/onboarding",
};

export const SACaf2DataDiriMeta = {
  title: "SA Hiring - CAF 2 - Data Diri",
  description: "SA Hiring - CAF 2 - Data Diri",
  path: "/sa-hiring/caf2/data-diri",
};

export const SACaf2StudyBankMeta = {
  title: "SA Hiring - CAF 2 - Pendidikan dan Informasi Bank",
  description: "SA Hiring - CAF 2 - Pendidikan dan Informasi Bank",
  path: "/sa-hiring/caf2/pendidikan-dan-informasi-bank",
};

export const SACaf2MaritalMeta = {
  title: "SA Hiring - CAF 2 - Pernikahan dan Tanggungan",
  description: "SA Hiring - CAF 2 - Pernikahan dan Tanggungan",
  path: "/sa-hiring/caf2/pernikahan-dan-tanggungan",
};

export const SACaf2ThankYouMeta = {
  title: "SA Hiring - CAF 2 - Terima Kasih",
  description: "SA Hiring - CAF 2 - Terima Kasih",
  path: "/sa-hiring/caf2/thank-you",
};

export const LiterasiKeuanganMeta = {
  title: "Literasi Keuangan",
  description: "Literasi Keuangan",
  path: "/literasi-keuangan",
};

export const CaraCerdasMeta = {
  title: "Cara Cerdas",
  description: "Cara Cerdas",
  path: "/literasi-keuangan/cara-cerdas",
};

export const GenerateLimitMeta = {
  title: "Formulir Pengajuan",
  description: "Formulir Pengajuan",
  path: "/HCIFormulir",
};

export const CashLoanMeta = {
  title: "Pembiayaan Tunai",
  description: "Pembiayaan Tunai",
  path: "/pembiayaan-tunai",
};

export const PLOMeta = {
  title: "Gunakan Home Credit BayarNanti Bisa penuhi kebutuhanmu - Home Credit",
  description: "Gunakan Home Credit BayarNanti Bisa penuhi kebutuhanmu",
  path: "/bayarnanti-belanja-online",
};